import { render, staticRenderFns } from "./LoginOrForgotPassword.vue?vue&type=template&id=6b506012&"
import script from "./LoginOrForgotPassword.vue?vue&type=script&lang=js&"
export * from "./LoginOrForgotPassword.vue?vue&type=script&lang=js&"
import style0 from "./LoginOrForgotPassword.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "LoginOrForgotPassword.vue"
export default component.exports